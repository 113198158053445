<template>
  <div>
    <!-- <standbyDialog
      :localLoadingDialog="localLoadingDialog"
      :key="localLoadingDialog"
    ></standbyDialog> -->
    <primaryButton
      class="mr-2"
      :attributesArray="functionElement['attributesArray']"
      type="button"
      :label="functionElement['functionName']"
      color="save"
      :disabled="calcDisabled"
      :loading="loading"
      icon="mdi-play-box-multiple"
      v-on="$listeners"
    ></primaryButton>
  </div>
</template>
<script>
// import { runTest } from "@/js/helper.js";
import primaryButton from "@/commonComponents/primaryButton.vue";
// import startParametersDialog from "@/components/startParametersDialog.vue";
// import standbyDialog from "@/commonComponents/standbyDialog.vue";
export default {
  props: [
    "isValid",
    "localLoading",
    "functionElement",
    "result",
    "loading",
    "calcDisabled",
  ],
  components: {
    primaryButton,
    // startParametersDialog,
    // standbyDialog,
  },
  data() {
    return {
      // localLoadingDialog: false,
      functionName: "",
      startParameters: [],
      parameterDialog: false,
     // loading: false,
    };
  },
  computed: {},
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    //console.log("before fetch");
    // this.fetchData("", () => {
    //   // this.getMenu();
    // });
    // this.getMenu();

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      // console.log(mutation.type);
      if (
        mutation.type === "dataSubmitted" &&
        typeof state.submittedData[this.result.dialogRef] != "undefined"
      ) {
        let functionName =
          state.submittedData[this.result.dialogRef].data.functionName;
        this.functionName = functionName;
        if (functionName == "editAndRun" || functionName == "newAndRun") {
          let caller = this;
          let n_id_key = "";
          let n_id = "";
          if (functionName == "editAndRun") {
            n_id_key = this.result.valueLine.n_id_key;
            n_id = this.result.valueLine.n_id;
          } else {
            let sentResult =
              state.submittedData[this.result.dialogRef].data.result;

            n_id_key = sentResult.addedIndexKey;
            n_id = sentResult.addedIndex;
          }

          caller.$store.commit("checkStartParameters", {
            n_id: n_id,
            n_id_key: n_id_key,
          });

          // caller.runTest(n_id, n_id_key);
          // caller.$store.commit("pageLoading", true);

          // caller
          //   .axios({
          //     method: "post",
          //     url:
          //       "serve.php?f=testing&f2=testsExecuteApi&function=exeuteandShowTestbyTestID&n_id=" +
          //       n_id +
          //       "&n_id_key=" +
          //       n_id_key,
          //     data: {},
          //     //headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
          //   })
          //   .then(function (response) {
          //     console.log(response);
          //     caller.$router
          //       .push(
          //         response.data.result
          //           .redirect /*, () => {
          //         caller.localLoadingDialog = false;
          //         caller.$store.commit("closeDialog", caller.result.dialogRef);
          //       }*/
          //       )
          //       .catch(() => {
          //         caller.$router.go();
          //       });
          //   })
          //   .catch(function (response) {
          //     //handle error
          //     console.log(response);
          //   });
        }
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },

  methods: {
    // runTest(n_id, n_id_key, execute) {
    //   if (execute) {
    //     runTest(n_id, n_id_key, this);
    //   } else {
    //     this.parameterDialog = false;
    //   }
    // },
    // editAndRun() {
    //   //  this.localLoadingDialog = true;

    //   this.$emit("submitted", this.functionElement.function);
    // },
  },
};
</script>